$body-color: black;

$brand-danger: #621300;
$brand-primary: #001f2f;

$enable-flex: true;

$font-family-serif: 'freight-display-pro', Helvetica, Georgia, sans-serif;
$font-family-base: $font-family-serif;

$font-size-root: 16px;

$trajan: 'trajan-pro-3', $font-family-serif;

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'sass/font-awesome-4.6.3/font-awesome';

.bio {
    @extend .row;

    &__content {
        @extend .col-md-7;

        &-body {
            font-size: $font-size-lg;
            line-height: 1.75;

            a {
                color: $brand-danger;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-heading {
            @extend .p-b-1;

            font-size: $font-size-h2;
        }
    }

    &__photo {
        @extend .col-md-3;
        @extend .col-md-offset-1;

        img {
            @extend .img-fluid;
        }
    }

    &__wrapper {
        background-color: $gray-lightest;
        padding-bottom: ($spacer * 2);
        padding-top: ($spacer * 2);

        border-bottom: 1px solid $gray-lighter;
    }
}

.footer {
    @extend .row;

    &__content {
        @extend .col-md-12;
        @extend .text-xs-center;

        color: white;
        line-height: 1.75;

        a {
            color: white;

            &:hover {
                color: white;
            }
        }
        
        p {
            @extend .m-b-0;
        }
    }
    
    &__list {
        @extend .nav;
        @extend .nav-inline;
        
        &-item {
            @extend .m-l-0;
            @extend .nav-item;
            @extend .p-a-0;
            
            &__link {
                @extend .nav-link;
                
                &:focus,
                &:hover {
                    text-decoration: underline;
                }
            }
            
            & + &:before {
                
                // The following space character is meaningful.
                content: '| ';
                padding: 0 ($spacer / 2);
            }
        }
    }

    &__wrapper {
        background-color: $brand-primary;
        padding-bottom: ($spacer * 2);
        padding-top: ($spacer * 2);
    }
}

.gform_wrapper {
    font-size: $font-size-base;
    margin: 0 0 15px 0;
    
    .field_sublabel_above {
        margin-bottom: 20px;
    }
    
    .gfield_error input[type=email],
    .gfield_error input[type=number],
    .gfield_error input[type=password],
    .gfield_error input[type=tel],
    .gfield_error input[type=text],
    .gfield_error input[type=url],
    .gfield_error textarea {
        border-color: #d9534f !important;
    }
    
    .gform_footer input[type=submit] {
        background-color: $brand-primary;
        border: 1px solid $brand-primary;
        color: #ffffff;
        display: block;
        font-family: "FontAwesome", $font-family-serif;
        padding: 8px 5px;
        transition: background-color 0.3s ease;
            -webkit-transition: background-color 0.3s ease;
            -moz-transition: background-color 0.3s ease;
        white-space: normal;
        width: 100%;
        
        &:focus,
        &:hover {
            background-color: transparent;
            color: $brand-primary;
            text-decoration: none;
        }
        
        // Different button style for sidebar.
        .inside-page__sidebar & {
            background-color: transparent;
            border: 1px solid #ffffff;
            color: #ffffff;
            
            &:focus,
            &:hover {
                background-color: #ffffff;
                color: $brand-primary;
                text-decoration: none;
            }
        }
    }
    
    .ginput_complex {
        
        .address_city {
            display: inline-block;
            margin-right: 2%;
            width: 69%;
        }
        
        .address_state {
            display: inline-block;
            width: 29%;
            
            select {
                border: 1px solid #d1d1d1;
                border-radius: 0;
                margin-bottom: 5px;
            }
        }
        
        input[type=text] {
            display: block;
            margin-bottom: 5px;
        }
        
        label {
            display: block;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    
    .ginput_container {
        white-space: nowrap;
    }
    
    input[type=email],
    input[type=number],
    input[type=password],
    input[type=tel],
    input[type=text],
    input[type=url],
    textarea {
        border: 1px solid #d1d1d1;
        display: inline-block;
        float: none;
        margin-bottom: 20px;
        padding: 5px !important;
        text-indent: 0 !important;
        vertical-align: top;
        width: 100%;
    }
        textarea {
            height: 8em;
        }
    
    .gform_fields {
        @extend .m-a-0;
        @extend .p-a-0;
        
        list-style-type: none;
    }
    
    .validation_error,
    .validation_message {
        background-color: #f2dede;
        border: 1px solid transparent;
        border-color: #ebccd1;
        color: #a94442;
        margin-bottom: 20px;
        padding: 15px;
    }
        .validation_message {
            margin-top: -15px;
            padding: 5px 15px;
        }
}

.header {
    @extend .row;

    &__logo {
        @extend .col-xs-12;
        @extend .col-sm-7;
        @extend .col-md-6;
        @extend .text-xs-center;
        @extend .text-sm-left;
    }

    &__phone {
        @extend .col-xs-12;
        @extend .col-sm-5;
        @extend .col-md-6;
        @extend .text-sm-right;
        @extend .text-xs-center;

        color: white;
        font-family: $trajan;
        font-size: $font-size-lg;
        line-height: 50px;

        a {
            color: white;
        }
    }

    &__wrapper {
        @extend .p-b-1;
        @extend .p-t-1;

        background-color: $brand-primary;
        position: sticky;
            top: 0;
        width: 100%;
        z-index: $zindex-navbar-sticky;
        
        &.\--is-home {
            position: fixed;
        }
    }
}

.home-content {
    @extend .row;

    &__body {
        @extend .col-md-12;
        @extend .p-b-1;
        @extend .text-xs-center;

        font-size: $font-size-lg;
        line-height: 1.75;
    }

    &__heading {
        @extend .col-md-12;
        @extend .p-b-1;
        @extend .text-xs-center;

        h1 {
            font-family: $trajan;
        }
    }

    &__list {
        @extend .list-unstyled;
        @extend .row;

        &-item {
            @extend .col-md-4;
            @extend .p-b-1;
            @extend .text-xs-center;

            font-size: $font-size-h4;
            position: relative;

            &:before {
                content: '•';
                font-size: $font-size-base;
                padding-right: ($spacer / 4);
                vertical-align: text-top;
            }
        }
    }

    &__wrapper {
        padding-bottom: ($spacer * 2);
        padding-top: ($spacer * 2);
    }
}

.home-map {
    @extend .row;

    padding-top: ($spacer * 3);
    
    @include media-breakpoint-down(md) {
        padding-bottom: ($spacer * 3);
        padding-top: ($spacer * 3);
    }

    &__inner {
        position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            
        @include media-breakpoint-down(md) {
            position: static;
        }
    }

    &__key {
        @extend .list-unstyled;
        @extend .m-b-0;
        @extend .text-xs-center;

        background-color: rgba($brand-primary, .75);
        color: white;
        padding: ($spacer * 2) $spacer;

        &-item {

            &.\--heading {
                @extend .m-b-1;

                font-size: $font-size-h4;
            }
        }
    }

    &__overlay {
        @extend .col-xs-12;
        @extend .col-md-6;
        @extend .col-lg-4;
    }

    &__wrapper {
        background-image: url('../img/home-map.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        &:before {
            content: '';
            display: block;
            padding-top: 33.33%;
            
            @include media-breakpoint-down(md) {
                content: none;
            }
        }
    }
}

.home-quote {
    @extend .row;
    
    position: relative;
    z-index: 69;

    &__body {
        @extend .col-xs-12;
        @extend .p-b-1;
        @extend .p-t-1;
        @extend .text-xs-center;

        color: white;
        font-size: $font-size-h4;
        line-height: 1.75;
    }

    &__logo {
        @extend .col-xs-12;
        @extend .text-xs-center;
    }

    &__source {
        @extend .col-xs-12;
        @extend .p-b-1;
        @extend .text-xs-center;

        color: white;
        font-size: $font-size-h4;
    }

    &__stars {
        @extend .col-xs-12;
        @extend .p-b-1;
        @extend .text-xs-center;

        color: yellow;
        font-size: $font-size-h3;
    }

    &__wrapper {
        background-attachment: fixed;
        background-image: url('../img/home-quote.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: ($spacer * 2);
        padding-top: ($spacer * 2);
        position: relative;
        width: 100%;
        
        &:after {
            background-color: rgba($brand-primary, 0.75);
            content: '';
            display: block;
            position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
            z-index: 2;
        }
    }
}

html, body {
    height: 100%;
}

.inside-page {
    @extend .row;

    &__main {
        @extend .col-md-8;
        
        font-size: $font-size-lg;
        
        &--contact-page {
            @extend .col-xs-12;
            @extend .col-sm-8;
            @extend .col-sm-offset-2;
            @extend .col-md-6;
            @extend .col-md-offset-3;
        }
        
        a {
	        text-decoration: underline;
	        
	        &:hover {
		        color: $brand-danger;
	        }
        }
    }
    
    &__sidebar {
        @extend .col-md-4;
        
        &__card {
            @extend .card;
            
            background-color: $brand-primary;
            border: none;
            border-radius: 0;
            
            &-block {
                @extend .card-block;
            }
            
            &-title {
                @extend .card-title;
                
                color: #ffffff;
            }
        }
    }
    
    &__wrapper {
        padding-bottom: ($spacer * 2);
        padding-top: ($spacer * 2);
    }
}

.overlay {
    @extend .p-b-1;
    @extend .p-t-1;
    @extend .row;

    &__button {
        @extend .btn;
        @extend .btn-block;
        @extend .btn-danger;
        @extend .btn-lg;

        background-color: rgba($brand-danger, 0.75);
        border: 1px solid $brand-danger;
        border-radius: ($spacer * 1.5);
        font-family: $font-family-sans-serif;
        font-size: $font-size-sm;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    
    &__element {
	    @extend .p-a-1;
	    @extend .text-xs-center;
	    
	    &.\--button {
		    @extend .col-xs-12;
		    @extend .col-md-4;
		    @extend .col-sm-pull-6;
		    @extend .col-md-pull-0;
	    }
	    
	    &.\--icon {
		    @extend .col-xs-6;
		    @extend .col-sm-3;
		    @extend .col-md-2;
		    @extend .hidden-xs-down;
		    
		    img {
			    @extend .img-fluid;
		    }
	    }
    }

    &__icon {
        @extend .col-xs-6;
        @extend .col-md-4;
        @extend .p-b-1;
        @extend .p-t-1;
        @extend .text-xs-center;

        color: white;
        
        img {
            max-width: 100%;
        }
        
        &--first {
            @extend .overlay__icon;
            @extend .col-xs-12;
            @extend .col-md-push-4;
        }
        
        &--second {
            @extend .overlay__icon;
            @extend .col-md-pull-4;
        }
    }

    &__text {
        @extend .col-xs-12;
        @extend .p-b-2;
        @extend .text-xs-center;

        color: white;
        font-family: $trajan;
        
        @include media-breakpoint-down(sm) {
            padding-bottom: ($spacer / 2) !important;
        }

        &-heading {
            font-size: $font-size-h3;
            
            @include media-breakpoint-down(xs) {
                font-size: $font-size-h6;
            }
        }

        &-subheading {
            font-size: $font-size-h2;
            text-transform: uppercase;
            
            @include media-breakpoint-down(xs) {
                font-size: $font-size-h5;
            }
        }
    }

    &__wrapper {
        margin-top: auto;
        position: relative;
        z-index: 69;
    }
}

.splash {
    background-color: black;
    background-image: url('../img/0012-main-graphic-2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    
    &:after {
        background-image: radial-gradient(rgba(black, 0), rgba(black, 1));
        content: '';
        display: block;
        position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        z-index: 2;
    }
}
